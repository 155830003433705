import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      dark: false,
      themes: {
        light: {
          primary: '#878787',
          secondary: '#fcfcfc',
          background: '#dddddd',
          navbar: '#ffffff',
          activeButton: '#2196f3',
          button: '#878787',
          dataTableRow: '#fff',
        },
        dark: {
          primary: '#000',
          secondary: '#fff',
          background: '#333333',
          navbar: '#141414',
          activeButton: '#2196f3',
          button: '#878787',
          dataTableRow: '#1e1e1e',
        },
      },
    },
  });