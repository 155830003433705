import graphService from '@/services/graphService';

export default {
  state: {
    azureAccount: null,
    authenticationInProgress: false,
    accountContacts: [],
    accountGroups: [],
  },
  getters: {
    azureAccount: (state) => state.azureAccount,
    azureAuthenticationInProgress: (state) => state.authenticationInProgress,
    accountContacts: (state) => state.accountContacts,
    accountGroups: (state) => state.accountGroups,
  },
  actions: {
    async OPEN_AZURE_AUTH({ commit }, tenant) {
      commit('setAzureAuthenticationInProgress', true);

      await graphService.loginPopup(tenant)
        .then(async () => {
          const myAccounts = graphService.getAllAccounts();
          commit('setAzureAccount', myAccounts[0]);

        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
          commit('setAzureAuthenticationInProgress', false);
        });
    },
    async LOGOUT_AZURE({ commit }) {
      commit('setAzureAccount', null);
      graphService.logout();
    },
    LOAD_SAVED_ACCOUNT({ commit }) {
      const myAccounts = graphService.getAllAccounts();
      if (myAccounts.length) {
        commit('setAzureAccount', myAccounts[0]);
      }
    },
    async LOAD_ACCOUNT_CONTACTS({ commit }, account) {
      const myContacts = await graphService.getContacts(account);
      if (myContacts) {
        commit('setAccountContacts', myContacts.value);
      }
    },
    async LOAD_GROUPS_AND_CONTACTS({ commit }, account) {
      const myContacts = await graphService.getContacts(account);
      if (myContacts) {
        commit('setAccountContacts', myContacts.value);
      }
      const temparr = [];
      for (let contact of myContacts.value) {
        if (contact.personalNotes !== "") {
          let userGroups = contact.personalNotes.split(',');
          for (let groupName of userGroups) {
            let added = false;
            const group = temparr.find(x => x.name == groupName);
            if (group !== undefined) {
              group.members.push({
                Name: contact.displayName,
                GivenName: contact.givenName,
                id: contact.id,
                Email: contact.emailAddresses[0].address,
              });
              added = true;
            }
            if (added == false) {
              const newItem = {
                name: groupName,
                members: [
                  {
                    Name: contact.displayName,
                    GivenName: contact.givenName,
                    id: contact.id,
                    Email: contact.emailAddresses[0].address,
                  }
                ]
              }
              temparr.push(newItem);
            }
          }
        }
      }
      if (temparr.length > 0) {
        commit('setAccountGroups', temparr);
      }
    }
  },
  mutations: {
    setAzureAccount: (state, account) => {
      state.azureAccount = account;
      state.authenticationInProgress = false;
    },
    setAzureTenants: (state, tenants) => {
      state.azureTenants = tenants;
    },
    setAzureAuthenticationInProgress: (state, authState) => {
      state.authenticationInProgress = authState;
    },
    setAccountContacts: (state, contacts) => {
      state.accountContacts = contacts;
    },
    setAccountGroups: (state, Groups) => {
      state.accountGroups = Groups;
    }
  },
};