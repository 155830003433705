import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const DEFAULT_TITLE = 'Applia Contant Manager';

const routes = [
    {
        path: '/',
        component: () => import('../views/Home.vue'),
        redirect: { name: 'Distributionslister' },
        children: [
            {
                path: 'distributionslister',
                name: 'Distributionslister',
                meta: {
                    title: 'Distributionslister',
                },
                component: () => import('@/views/Distributionslister.vue'),
            },
            {
                path: 'contacts',
                name: 'Contacts',
                meta: {
                    title: 'Contacts',
                },
                component: () => import('@/views/Contacts.vue'),
            },
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title ? `${to.meta.title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
    });
});

export default router;