import Vue from 'vue';
import Vuex from 'vuex';
import azure from './azure';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    azure,
  },
});
