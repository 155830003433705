import { PublicClientApplication, AccountEntity } from '@azure/msal-browser';
import axios from 'axios';

const msal = new PublicClientApplication({
  auth: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    authority: process.env.VUE_APP_AZURE_AUTHORITY,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
});
const graphService = {
  graphPath: 'https://graph.microsoft.com/beta',
  msal,
  async loginPopup(tenant) {
    return this.msal.loginPopup({ redirectUri: '/', prompt: tenant ? null : 'select_account', domainHint: tenant?.defaultDomain });
  },
  getAllAccounts() {
    return this.msal.getAllAccounts();
  },
  async acquireToken(payload) {
    const query = {
      scopes: payload.scopes,
      account: payload.account,
      redirectUri: '/'
    };

    let response;
    try {
      response = await this.msal.acquireTokenSilent(query);
    }
    catch {
      response = await this.msal.acquireTokenPopup(query);
    }


    return response;
  },
  getContacts(account) {
    return this.graphGet('me/contacts', { account, scopes: ['Contacts.ReadWrite'] });
  },
  postContact(account, newContact) {
    return this.graphPost('me/contacts', { account, scopes: ['Contacts.ReadWrite'] }, newContact)
  },
  editContact(account, id, updatedContact) {
    return this.graphPatch('me/contacts/' + id, { account, scopes: ['Contacts.ReadWrite'] }, updatedContact)
  },
  deleteContact(account, id) {
    console.log(id);
    return this.graphDelete('me/contacts/' + id, { account, scopes: ['Contacts.ReadWrite'] })
  },
  async graphGet(path, tokenRequest) {
    const tokenResponse = await this.acquireToken(tokenRequest);
    const response = await axios.get(`${this.graphPath}/${path}`, {
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`
      }
    });

    return response.data;
  },
  async graphPatch(path, tokenRequest, params) {
    const tokenResponse = await this.acquireToken(tokenRequest);
    const response = await axios.patch(`${this.graphPath}/${path}`, params, {
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`
      }

    });

    return response.data;
  },
  async graphPost(path, tokenRequest, params) {
    const tokenResponse = await this.acquireToken(tokenRequest);
    const response = await axios.post(`${this.graphPath}/${path}`, params, {
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`
      }

    });

    return response.data;
  },
  async graphDelete(path, tokenRequest) {
    const tokenResponse = await this.acquireToken(tokenRequest);
    const response = await axios.delete(`${this.graphPath}/${path}`, {
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`
      }

    });

    return response.data;
  },
  logout() {
    msal.browserStorage.clear();
  },
  removeAccount(account) {
    return msal.browserStorage.removeItem(AccountEntity.generateAccountCacheKey(account));
  }
}

export default graphService;