const util = {

    FirstEmailInArr(value) {
        return value[0].address;
    },
    ShowGroups(value) {
        return value.replace(',', ', ');
    }
}


export default util;
